<template>
  <div>
    <el-dialog title="绑定邮箱" width="30%" :visible.sync="visible">
      <el-form :model="form" ref="form" :rules="rules" label-width="130px">
        <el-form-item label="当前绑定邮箱：">
          <div><span v-if="form.oldEmail">{{ form.oldEmail }}</span><span v-else style="color: orangered;">未绑定</span></div>
        </el-form-item>
        <el-form-item label="新邮箱：" prop="email">
          <el-input v-model="form.email" clearable placeholder="请输入邮箱"></el-input>
        </el-form-item>
        <el-form-item label="验证码：" v-if="form.email !== ''" prop="verificationCode">
          <div style="display: flex">
            <div style="flex: 1;">
              <el-input v-model="form.verificationCode" clearable placeholder="请输入验证码"></el-input>
            </div>
            <div style="margin-left: auto; padding-left:10px;">
              <el-button v-if="show" @click="getCode">获取验证码</el-button>
              <el-button disabled v-else>{{ count }}s后重新获取</el-button>
            </div>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { sendBindEmailVerificationCode, updateEmail } from '@/api/personalCenter'
export default {
  name: 'emailDiaolg',
  data() {
    return {
      visible: false,
      btnLoading: false,
      show: true,
      form: {
        oldEmail: '',
        email: '',
        verificationCode: ''
      },
      rules: {
        email: [
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        ],
        verificationCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      },
      timer: null,
      count: 0
    }
  },
  methods: {
    init(email) {
      this.visible = true
      this.show = true
      this.timer = null
      this.form = {
        oldEmail: email,
        email: '',
        verificationCode: ''
      }
    },
    // 获取验证码
    async getCode () {
      await sendBindEmailVerificationCode({
        email: this.form.email
      }).then(res => {
        this.$message.success('验证码已发送至您的邮箱！请前往查看。')
      })
      const TIME_COUNT = 60
      if (!this.timer) {
        this.count = TIME_COUNT
        this.show = false
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--
          } else {
            this.show = true
            clearInterval(this.timer)
            this.timer = null
          }
        }, 1000)
      }
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          updateEmail(this.form).then(res => {
            this.$message.success('操作成功！')
            this.visible = false
            this.$emit('pageList')
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
