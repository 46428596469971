<template>
  <div style="background: #eef0f5; height: 100vh; overflow: hidden; display: flex; flex-direction: column;">
    <navbar />
    <div style="flex: 1; padding: 10px; box-sizing: border-box; display: flex; flex-direction: column;">
      <div style="background: #fff; padding: 10px; border-radius: 8px;">
        <div style="border-left: 4px solid #1e78cd; padding: 2px 6px; color: #333; font-weight: bold; font-size: 16px; display: flex;">
          <div style="flex: 1; line-height: 28px;">个人信息</div>
          <div><el-button size="mini" type="primary" @click="editPW">修改登录密码</el-button></div>
        </div>
        <div style="margin-top: 15px;">
          <el-descriptions class="margin-top" :column="3" border :label-style="{width: '120px'}">
            <el-descriptions-item label="头像">
              <el-image
                v-if="userInfo.info.headImg"
                :preview-src-list="[userInfo.info.headImg]"
                :src="userInfo.info.headImg"
                style="width: 50px; height: 50px;"
              />
              <span v-else>-</span>
            </el-descriptions-item>
            <el-descriptions-item label="身份证姓名">{{ userInfo.info.name }}</el-descriptions-item>
            <el-descriptions-item label="身份证号">{{ userInfo.info.idcode }}</el-descriptions-item>
            <el-descriptions-item label="用户ID">{{ userInfo.info.id }}</el-descriptions-item>
            <el-descriptions-item label="登录账号">{{ userInfo.info.mgtAccount }}</el-descriptions-item>
            <el-descriptions-item label="名称">{{ userInfo.info.nickName }}</el-descriptions-item>
            <el-descriptions-item label="手机号">{{ userInfo.info.phone }}</el-descriptions-item>

            <el-descriptions-item label="公众号UID">{{ userInfo.info.updateBy }}</el-descriptions-item>

            <el-descriptions-item label="名族">{{ userInfo.info.nationName }}</el-descriptions-item>
            <el-descriptions-item label="生日">{{ userInfo.info.birthDate }}</el-descriptions-item>
            <el-descriptions-item label="婚姻状况">{{ userInfo.info.maritalStateName }}</el-descriptions-item>
            <el-descriptions-item label="学历">{{ userInfo.info.educationLevelName }}</el-descriptions-item>
            <el-descriptions-item label="政治面貌">{{ userInfo.info.politicalLevelName }}</el-descriptions-item>

            <el-descriptions-item label="所属公司">{{ userInfo.info.company }}</el-descriptions-item>

            <el-descriptions-item label="联系地址">{{ userInfo.info.idAddress }}</el-descriptions-item>
            <el-descriptions-item label="紧急联系人">{{ userInfo.info.urgentUser }}</el-descriptions-item>
            <el-descriptions-item label="紧急联系人关系">{{ userInfo.info.urgentUserRelationName }}</el-descriptions-item>
            <el-descriptions-item label="紧急联系人电话">{{ userInfo.info.urgentUserPhone }}</el-descriptions-item>
            <el-descriptions-item label="紧急联系人地址">{{ userInfo.info.urgentUserAddress }}</el-descriptions-item>
            <el-descriptions-item label="邮箱">
              <span v-if="userInfo.info.email">{{ userInfo.info.email }}</span><span v-else style="color: orangered;">未绑定</span><el-button type="text" style="margin-left: 100px;" @click="bindEmail">绑定邮箱</el-button><span style="color: orangered;">（绑定邮箱后可使用邮箱号登录）</span>
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>
      <div style="flex: 1; display: flex; flex-direction: column; margin-top: 10px; background: #fff; padding: 10px; border-radius: 8px;">
        <div style="border-left: 4px solid #1e78cd; padding: 2px 6px; color: #333; font-weight: bold; font-size: 16px;">登录日志</div>
        <div class="tableBox">
          <div class="tableList">
            <el-table
              header-cell-class-name="table-header"
              :data="tableData"
              border
              style="width: 100%; font-size: 14px;"
            >
              <el-table-column label="序号" width="50" type="index"></el-table-column>
              <el-table-column label="时间" prop="attemptLoginTime"></el-table-column>
              <el-table-column label="IP" prop="attemptLoginIp"></el-table-column>
              <el-table-column label="是否登录成功">
                <template v-slot="scope">
                  <el-tag v-if="scope.row.succeed === 1" type="success">成功</el-tag>
                  <el-tag v-else type="danger">失败</el-tag>
                </template>

              </el-table-column>
              <el-table-column label="操作系统" prop="operatingSystem"></el-table-column>
              <el-table-column label="设备类型" prop="operatingSystemDeviceType"></el-table-column>
              <el-table-column label="浏览器" prop="browser"></el-table-column>
              <el-table-column label="浏览器设备类型" prop="browserDeviceType"></el-table-column>
              <!--            <el-table-column label="请求头信息" prop="action"></el-table-column>-->
              <el-table-column label="客户端编码" prop="clientCode"></el-table-column>
            </el-table>
            <div style="padding: 10px; text-align: right;">
              <el-pagination
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="current"
                :page-sizes="[10, 20, 30, 50, 100]"
                :page-size="pageSizes"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <editPassword ref="editP" />
    <emailDiaolg ref="emailD" @pageList="getUserInfo" />
  </div>
</template>

<script>
import navbar from '@/layout/components/Navbar'
import editPassword from './components/editPassword'
import emailDiaolg from './components/emailDiaolg'
import { getUser, loginLog } from '@/api/personalCenter'
export default {
  name: 'personal_center',
  components: {
    navbar,
    editPassword,
    emailDiaolg
  },
  data() {
    return {
      userInfo: {},
      pageSizes: 10,
      current: 1,
      total: 0,
      tableData: []
    }
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    getUserInfo() {
      getUser().then(res => {
        this.userInfo = res
        this.getLoginLog()
      })
    },
    getLoginLog() {
      loginLog({
        current: this.current,
        size: this.pageSizes,
        loginAccount: this.userInfo.info.mgtAccount
      }).then(res => {
        this.tableData = res.records
        this.total = res.total
      })
    },
    handleSizeChange(val) {
      this.pageSizes = val
      this.getLoginLog()
    },
    handleCurrentChange(val) {
      this.current = val
      this.getLoginLog()
    },
    // 修改密码
    editPW() {
      this.$refs.editP.init()
    },
    // 绑定邮箱
    bindEmail() {
      const email = this.userInfo.info.email ? this.userInfo.info.email : null
      this.$refs.emailD.init(email)
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .el-descriptions .is-bordered {
  table-layout: fixed;
  .noBorder {
    border-top: 0;
  }
}
.tableBox{
  flex: 1;
  margin-top:15px;
  overflow: hidden;
  .tableList{
    padding: 10px;
    background: #fff;
    height: calc(100vh - 515px);
    overflow: hidden;
    ::v-deep .el-table{
      height: calc(100% - 55px);
      .el-table__body-wrapper{
        height: calc(100% - 50px);
        overflow-y: auto;
      }
    }
  }
}
</style>
