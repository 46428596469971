import request from '@/utils/request'

/**
 * 修改密码
 * /rbac/user/updateUserPwd
 */
export function updateUserPwd(data) {
  return request({
    url: '/rbac/user/updateUserPwd',
    method: 'post',
    data
  })
}

/**
 * 获取用户信息  /driver/user/getUser
 */
export function getUser (data) {
  return request({
    url: '/driver/user/getUser',
    method: 'get',
    params: data
  })
}

/**
 * 操作日志  loginLog
 */
export function loginLog(data) {
  return request({
    url: '/loginLog',
    method: 'post',
    data
  })
}

/**
 * 获取绑定邮箱验证码  /driver/user/sendBindEmailVerificationCode
 */
export function sendBindEmailVerificationCode(data) {
  return request({
    url: '/driver/user/sendBindEmailVerificationCode',
    method: 'post',
    data
  })
}

/**
 * 邮箱保存  /driver/user/updateEmail
 */
export function updateEmail(data) {
  return request({
    url: '/driver/user/updateEmail',
    method: 'post',
    data
  })
}
