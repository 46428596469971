<template>
  <div class="main-navbar">
    <div class="left-menu" v-bind:style="{maxWidth: `calc(100% - ${subAppsWidth}px)`}">
      <hamburger id="hamburger-container" :is-active="sidebar.opened" class="hamburger-container" @toggleClick="toggleSideBar" />

      <div class="sub-apps-container">
        <sub-apps></sub-apps>
      </div>
    </div>
    <div
      class="right-menu"
    >
      <el-link href="https://www.yuque.com/books/share/4f389cc3-e809-46c4-be35-6c7514d0e116?#" target="_blank">系统使用教程</el-link>
      <search v-if="device!=='mobile'" id="header-search" @search="searchFocus" class="search-box right-menu-item" />
      <div class="right-menu-end">
        <screenfull v-if="device!=='mobile'" id="screenfull" class="right-menu-item hover-effect" />
        <el-dropdown class="avatar-container right-menu-item hover-effect" trigger="click">
          <div class="avatar-wrapper">
            <img src="../../assets/images/avatar.png" class="user-avatar">
            <span>Hi，{{ name }}</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown">
<!--            <el-dropdown-item divided @click.native="editPwdVisuble = true">
              <span style="display:block;">修改密码</span>
            </el-dropdown-item>-->
            <el-dropdown-item divided @click.native="jumpsToCenter">
              <span style="display:block;">个人中心</span>
            </el-dropdown-item>
            <el-dropdown-item divided @click.native="logout">
              <span style="display:block;">退出系统</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <!-- 修改密码弹窗 -->
    <edit-password :visible="editPwdVisuble" @close="editPwdVisuble = false"></edit-password>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import SubApps from './SubApps'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import Search from '@/components/HeaderSearch'
import EditPassword from './EditPassword'

export default {
  components: {
    SubApps,
    Hamburger,
    // ErrorLog,
    Screenfull,
    // SizeSelect,
    Search,
    EditPassword
  },
  computed: {
    ...mapGetters([
      'sidebar',
      'name',
      'avatar',
      'device',
      'theme'
    ])
  },
  data () {
    return {
      subAppsWidth: 260,
      editPwdVisuble: false
    }
  },
  mounted () {
    this.subAppsWidth = document.querySelector('.main-navbar .right-menu').clientWidth + 20
  },
  methods: {
    toggleSideBar () {
      this.$store.dispatch('app/toggleSideBar')
    },
    searchFocus (val) {
      this.$nextTick(() => {
        const clientWidth = document.querySelector('.main-navbar .right-menu').clientWidth + 20
        this.subAppsWidth = val ? (clientWidth + 250) : clientWidth
      })
    },
    // 个人中心
    jumpsToCenter() {
      this.$router.push('/personalCenter')
    },
    // 退出登录
    async logout () {
      this.$confirm('确定要退出登录吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        console.log(this.$store.state.user.isLoging)
        await this.$store.dispatch('user/logout')
        // this.$router.push('/login')
        if (this.$store.state.user.isLoging) {
          window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
        } else {
          this.$router.push('/login?redirect=' + this.$route)
        }
        // this.$router.push('/login?redirect=' + this.$route)
        this.$message({
          type: 'success',
          message: '已退出登录!'
        })
        this.$store.commit('user/SET_ISLOGING', false)
      }).catch(() => {})
    }
  }
}
</script>

<style lang="scss" scoped>
.main-navbar {
  height: 50px;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0,21,41,.08);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  flex-wrap: nowrap;
  z-index: 1;

  .left-menu {
    display: flex;
    align-items: center;
    flex: 1;
  }

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    cursor: pointer;
    transition: background .3s;
    -webkit-tap-highlight-color:transparent;

    &:hover {
      background: rgba(0, 0, 0, .025)
    }
  }

  .sub-apps-container {
    height: 46px;
    border: 0;
    max-width: calc(100% - 55px);
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    height: 100%;
    line-height: 50px;
    overflow: hidden;
    display: flex;
    flex-wrap: nowrap;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background .3s;

        &:hover {
          background: rgba(0, 0, 0, .025)
        }
      }
    }

    .right-menu-end {
      display: flex;
      flex-wrap: nowrap;
    }

    .avatar-container {

      .avatar-wrapper {
        display: flex;
        align-items: center;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 10px;
        }

        span {
          margin: 0px 8px 0px 10px;
          font-size: 14px;
          white-space: nowrap;
        }

        .el-icon-caret-bottom {
          cursor: pointer;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
