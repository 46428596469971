<template>
  <div>
    <el-dialog style="z-index: 3000;" title="修改密码" width="40%" :visible.sync="editPassword">
      <el-form :model="form" ref="form" :rules="rules" label-width="100px">
        <el-form-item label="新密码" prop="password">
          <el-input v-model="form.password" :type="passwordType" clearable placeholder="请输入密码" ></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password1">
          <el-input v-model="form.password1" :type="passwordType" clearable placeholder="请输入密码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editPassword = false">取 消</el-button>
        <el-button type="primary" @click="updataPassword">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateUserPwd } from '@/api/personalCenter'
export default {
  name: 'editPassword',
  data() {
    return {
      editPassword: false,
      passwordType: 'password',
      form: {
        password: '',
        password1: ''
      },
      rules: {
        password: [{ required: true, message: '密码不能为空', trigger: 'blur' }],
        password1: [{ required: true, message: '确认密码不能为空', trigger: 'blur' }]
      }
    }
  },
  methods: {
    init() {
      this.editPassword = true
      this.form = {
        password: '',
        password1: ''
      }
    },
    updataPassword () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.form.password === this.form.password1) {
            updateUserPwd({
              id: this.$store.state.user.userId,
              password: this.form.password
            }).then(async (res) => {
              if (res === true) {
                this.$message.success('密码修改成功！')
                this.editPassword = false
                await this.$store.dispatch('user/logout')
                if (this.$store.state.user.isLoging) {
                  window.location.href = process.env.VUE_APP_LOGIN_URL + `?redirect=${window.location.href}`
                } else {
                  this.$router.push('/login?redirect=' + this.$route)
                }
              }
            })
          } else {
            this.$message.warning('密码不一致请重新输入！')
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
